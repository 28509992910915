@import '/src/styles/colors.scss';

.body {
  display: block;
  background: $red;
  padding: 15px 20px;
color: white;
  margin: 30px auto;
}

@media (max-width: 960px) {
  .body {
    margin: 25px auto -10px;
  }
}