.container {

  max-width: 1200px;
  padding: 0 30px;
  margin: auto;
  height: 100%;

  @media(max-width: 960px) {
    padding: 0 15px;
  }

}