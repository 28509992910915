@import '/src/styles/colors.scss';
@import '/src/styles/mixins.scss';
@import '/src/styles/keyframes.scss';

@mixin input {
  position: relative;
  padding-top: 18px;
  padding-bottom: 1px;

  &::after {
    position: absolute;
    display: block;
    content: "";
    border-radius: 3px;
    height: 3px;
    width: 0%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: width .4s ease-in-out, background .3s ease-in-out;
    background: $pink

  }

  .input {
    position: relative;
    background: transparent;
    border: none;
    border-bottom: 1px solid $gray;
    padding: 6px 6px 6px 0;
    width: 100%;
    caret-color: $pink;
    transition: color .2s ease-in-out;
    text-align: center;

    &:focus {
      animation: caret .4s ease-in-out;
    }
  }



  @keyframes caret {
    from {
      caret-color: transparent;
    }
    to {
      caret-color: $pink;
    }
  }


  .textarea {
    padding: 10px 20px;
    margin-bottom: -3px;
  }

  .label {
    pointer-events: none;
    position: absolute;
    transition: all .3s ease-in-out;
    top: 25px;
    left: 50%;
    color: $gray;
    transform: translateX(-50%);
    white-space: nowrap;

    &__up {
      top: 0px;
      @include textSmall;
      color: $pink
    }
  }

}


.red {
  @include input;
}

.blue {
  @include input;

  .input {
    caret-color: $blue;

  }

  &::after {
    background: $blue
  }

  .label__up {
    color: $blue
  }
}

.use::after {
  width: 100%;
}


.hint {

  z-index: 100;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  display: flex;
  flex-direction: column;
  align-items: stretch;


}

.hint__item {


  background: $pink;
  transition: background .3s ease-in-out;

  &:hover {
    background: $gray;

  }
}

.hint__item:last-child {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.hint__button {
  white-space: nowrap;
  transition: color .3s ease-in-out;
  color: white;
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 6px 10px;
}


@media (max-width: 960px) {
  .hint__button {
    padding: 10px 10px;
  }
}




