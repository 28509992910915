@import "/src/styles/colors.scss";
@import "/src/styles/mixins.scss";

.item {
  padding: 30px 30px 20px;
  border-radius: 30px;
  background: white;

  &:not(:last-child) {
    margin-bottom: 50px;
  }

  &__body {
    display: flex;
    flex-direction: row;
    align-items: stretch;

  }

  &__bottom {
    border-top: 1px solid $pink;
    padding-top: 20px;
    margin-top: 25px;
    text-align: center;
  }
}

.image {
  object-fit: cover;
  width: 150px;
  height: 150px;
  border-radius: 15px;
}

.content {
  display: flex;
  align-items: stretch;
  padding-left: 50px;
  height: 100%;
  gap: 30px;

  &__right {
    @include column;
    gap: 20px;
    padding-right: 50px;

    .name {
      @include textSectionTitle;
    }

    .price {
      @include textSectionTitle;
    }
  }

  &__block {
    margin-top: 10px;
    @include column;
    align-items: start;
    gap: 10px;
    flex-wrap: wrap;

    .title {
      font-weight: 500;
      margin-right: 10px;
    }

    .value {
      color: $pink;
    }

    .line {
      display: flex;
      align-items: center;
      justify-content: start;
    }
  }
}


@media (max-width: 960px) {

  .counter {
    margin-top: -8px;
  }

  .item {
display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;

    &__bottom {

      padding-top: 10px;
    }
    &__body {
      display: block;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      gap: 30px;
      width: calc(100vw/2 - 53px);
    }
  }

  .item:not(:last-child) {
    margin-bottom: 0px;

  }

  .image {
    border-radius: 20px;
    width: calc(100vw/2 - 53px);
    height: calc(100vw/2 - 53px);
    margin-bottom: 20px;
  }
  .content {

    width: 100%;
    flex-direction: column;
    flex-grow: 0;
    padding-left: 0;
    gap: 10px;
    &__right {
      padding-right: 0;
      .name {
        text-align: center;
      }
    }

    &__block {
      .title {
        margin-bottom: 3px;
      }
      .line {
        align-items: start ;

      }
      .value {

      }
    }
  }
}

@media (max-width: 380px) {
  .item {
    &__body {
      width: 100%;
    }
  }

  .image {
    border-radius: 20px;
    width: calc(100vw - 60px);
    height: calc(100vw - 60px);
  }
}