@import '/src/styles/mixins.scss';

.body {
  display: flex;
  flex-direction: column;

  .display {
    height: 500px;
    width: 500px;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
    border-radius: 30px;

    &__wrapper {
      transition: left .4s ease-in-out;
      display: flex;
      position: absolute;
      top: 0px;
      bottom: 0px;
    }

    &__item {
      width: 500px;
      max-height: 600px;
    }

    &__image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin-right: -10px;
    margin-left: -10px;
    border-radius: 15px;

    &__item {
      border-radius: 15px;
      padding: 10px;
    }

    &__button {
      padding: 0;
      transition: opacity .3s ease-in-out;
    }

    &__image {
      border-radius: 15px;
      object-fit: cover;
      width: 100px;
      height: 100px;
    }
  }

}

@media (max-width: 960px) {
  .body {
    margin-bottom: 25px;
    .display {
      width: calc(100vw - 30px);
      height: calc(100vw - 30px);
      &__item {
        width: calc(100vw - 30px);
        max-height: calc(100vw - 30px);
      }
    }
    .buttons {
      margin-right: -5px;
      margin-left: -5px;
      &__item {
        padding: 5px;
      }
      &__image {
        width: calc((100vw - 30px) / 5 - 10px);
        height: calc((100vw - 30px) / 5 - 10px);
      }
    }
  }

}

@media (max-width: 500px) {
  .body {
    .buttons {
      &__image {
        width: calc((100vw - 30px) / 4 - 10px);
        height: calc((100vw - 30px) / 4 - 10px);
      }
    }
  }
}

@media (max-width: 360px) {
  .body {
    .buttons {
      &__image {
        width: calc((100vw - 30px) / 3 - 10px);
        height: calc((100vw - 30px) / 3 - 10px);
      }
    }
  }
}