@import "/src/styles/mixins.scss";
@import "/src/styles/colors.scss";

.list {
  padding-top: 40px;
}

.item {
  border-radius: 30px;
  padding: 30px;
  background: white;
  &__top {
padding-bottom: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid  $pink;
    .image {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      object-fit: cover;
      margin-right: 30px;
    }
    .name {
      @include textSectionTitle;
    }
  }

  &__body {
    padding-top: 20px;
    white-space: pre-wrap;
  }

  &__buttons {
    margin-top: 20px;
    border-top: 1px solid $pink;
    padding-top: 20px;

  }

  .button {
    margin-left: -10px;
  }
}

.item:not(:last-child) {
  margin-bottom: 30px;
}



.mock {
  text-align: center;
}

@media (max-width: 960px) {

  .list {
    padding-top: 10px;
  }
  .item {
    border-radius: 15px;
    padding: 20px;
    &__top {
      padding-bottom: 10px;
      padding-right: 45px;
      .image {
        width: 30px;
        height: 30px;
        margin-right: 15px;
      }

      .name {
        flex-grow: 1;
        text-align: center;
      }
     }
    &__body {
      padding-top: 15px;
    }

    &__buttons {
      padding-top: 5px;
      margin-top: 15px;
      margin-bottom: -10px;
    }

    .button {
      margin-left: -14px;
    }
  }

}