@import "/src/styles/mixins.scss";

.text {
  text-align: center;
  margin-bottom: 80px;
  max-width: 600px;
margin-right: auto;
  margin-left: auto;
}

.body {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.loading {
  margin-top: 10px;
}

.text_small {
  margin-top: 10px;
  @include textSmall;
  text-align: center;
}

.button_block {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 30px;
}

.button {
  margin-top: 30px;

}

.button_forgotten {

  margin-top: 10px;

}

@media (max-width: 960px) {
  .text {
    margin-bottom: 30px;
  }
}