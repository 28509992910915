@import "/src/styles/mixins.scss";
@import "/src/styles/colors.scss";


.title {
  font-weight: 500;
  font-size: 48px;
  line-height: 52px;
  color: white;
}

.text {
  @include textPageTitle;
  font-weight: 400;
  color: white;
}

.body {
  margin-bottom: 80px;
  width: 100%;
  min-height: 500px;
  padding: 40px 50px;
  border-radius: 30px;
  background: $pink;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__top {
    flex-grow: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 70px;
  }

  &__content {
    margin-left: 50px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.button_group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  @include textSectionTitle;
  font-weight: 600;
  padding: 15px 20px;
  background: white;
  color: $pink;
}

.image {
  width: 50%;
  object-fit: cover;
  border-radius: 30px;
}

@media (max-width: 960px) {

  .image {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 15px;
  }

  .title {
    @include textSectionTitle;
    color: white;
    text-align: center;
    font-weight: 500;

  }

  .text {
    @include textCommon;
    text-align: center;
    color: white;
  }

  .body {
border-radius: 15px;
    padding: 20px 20px;
    margin-bottom: 20px;

    &__top {
      flex-direction: column;
      padding-bottom: 20px;
    }

    &__content {
      margin-left: 0px;
      gap: 10px;
    }
  }

  .button_group {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }

  .button {
    font-size: 18px;
    width: 100%;
    padding: 10px 5px;
  }


}



