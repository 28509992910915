@font-face {
  font-display: swap;
  font-weight: normal;
  font-family: 'Corinthia';
  font-style: normal;
  src: url(../fonts/corinthia.woff2) format("woff2"), url(../fonts/corinthia.woff) format("woff"), url(../fonts/corinthia.ttf) format("truetype");
}

@font-face {
  font-display: swap;
  font-weight: 200;
  font-family: 'Proxima Soft';
  font-style: normal;
  src: url(../fonts/ProximaSoft-Thin.woff2) format("woff2"), url(../fonts/ProximaSoft-Thin.woff) format("woff")
}

@font-face {
  font-display: swap;
  font-weight: 300;
  font-family: 'Proxima Soft';
  font-style: normal;
  src: url(../fonts/ProximaSoft-Light.woff2) format("woff2"), url(../fonts/ProximaSoft-Light.woff) format("woff")
}

@font-face {
  font-display: swap;
  font-weight: 400;
  font-family: 'Proxima Soft';
  font-style: normal;
  src: url(../fonts/ProximaSoft-Regular.woff2) format("woff2"), url(../fonts/ProximaSoft-Regular.woff) format("woff")
}


@font-face {
  font-display: swap;
  font-weight: 500;
  font-family: 'Proxima Soft';
  font-style: normal;
  src: url(../fonts/ProximaSoft-SemiBold.woff2) format("woff2"), url(../fonts/ProximaSoft-SemiBold.woff) format("woff")
}

@font-face {
  font-display: swap;
  font-weight: 600;
  font-family: 'Montserrat';
  font-style: normal;
  src: url(../fonts/ProximaSoft-Black.woff2) format("woff2"), url(../fonts/ProximaSoft-Black.woff) format("woff")
}