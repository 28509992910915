.body {

  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper {
  position: relative;
  width: 250px;
  height: 300px;
  padding-bottom: 15px;
}

.image {
  object-fit: cover;
  border-radius: 15px;
  width: 100%;
  height: 100%;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
