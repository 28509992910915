@import "./src/styles/App.scss";

.logo {
  @include flexLine;
  align-items: flex-end;
}

.image {
  max-width: 92px;
  margin-right: 11px;
}

.text {

  color: white;
  font-family: "Corinthia";
  font-size: 56px;
  line-height: .7;

}

@media (max-width: 960px) {

  .logo {

  }

  .image {
    max-width: 50px;
    margin-right: 16px;
  }

  .text {
    flex-grow: 1;
    font-size: 31px;

  }
}
