
@import './font.scss'; @import "./colors.scss"; @import "./mixins.scss"; @import "./common.scss"; @import "./cursor.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
 @include textCommon;
  box-sizing: border-box;
}

.disabled {
  pointer-events: none;
  opacity: .7;
}

.page-title {
  @include textPageTitle;
  margin-bottom: 80px;
  text-align: center;
  position: relative;
}

.section-title {
  @include textSectionTitle;
  margin-bottom: 30px;
  text-align: center;
}

.m-top-2 {
  margin-top: 30px;
}

.center {
  margin-right: auto;
  margin-left: auto;
}

.column {
  display: flex;
  flex-direction: column;
}

@media (max-width: 960px) {
  .section-title {

    margin-bottom: 11px;

  }

  .page-title {

    margin-bottom: 46px;

  }
}


