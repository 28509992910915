a {
  text-decoration: none;
}

h1, h2, h3, h4, p {
  margin: 0;
}

button {
  background: transparent;
  border: none;
  padding: 0;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

:focus {
 outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {

  display: none;
}


