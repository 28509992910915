@import "/src/styles/mixins.scss";

.mb {
  margin-bottom: 40px;
}

.modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: appear .4s ease-in-out ;

  &__text {
    @include textSectionTitle;
    text-align: center;
    margin-bottom: 30px;
  }

}

.content {
margin-top: 50px;
  margin-bottom: 10px;
}

@media (max-width: 960px) {

  section:not(:last-child) {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }

  .title {
    margin-bottom: 0px;
  }
  .content {
    margin-bottom: 20px;
  }
}