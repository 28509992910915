@import "/src/styles/colors.scss";

.body {
  width: 20px;
  height: 20px;
  position: relative;
  background: white;
  padding: 0;
  border-radius: 100%;
  flex-shrink: 0;
}

.point {
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  background: rgba($pink, .8);
  transition: transform .3s ease-in-out;
  transform: scale(0);
  border-radius: 100%;
}

.checked {
  transform: scale(1);
}
